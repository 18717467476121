<template>
    <div>
        <nl-loader v-if="preloading" :show="true" />
        <div v-else>
            <div class="box">
                <div class="row">
                    <div class="col">
                        <div class="d-flex justify-content-between">
                            <b-button type="is-warning" icon-pack="fas" icon-left="plus-circle" @click="openAlertModal">
                                Ajouter une alerte
                            </b-button>
                            <modal
                                v-model="showAlertModal"
                                :modal-title="alertModalTitle"
                                @cancel="showAlertModal = false"
                                @save="addOrEditAlert"
                            >
                                <div v-if="modalLoading">
                                    <div class="d-flex justify-content-center align-items-center preloading">
                                        <div class="loader-xl" />
                                    </div>
                                </div>
                                <template v-else>
                                    <form>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <b-field :label="$t('alertes-partenaires.text')">
                                                    <b-input
                                                        v-model="modalData.label"
                                                        :placeholder="$t('alertes-partenaires.text')"
                                                        required
                                                    />
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-6">
                                                <b-field :label="$t('alertes-partenaires.frequency')">
                                                    <b-select
                                                        v-model="modalData.frequences"
                                                        :placeholder="$t('alertes-partenaires.frequency')"
                                                        required
                                                        expanded
                                                    >
                                                        <option
                                                            v-for="(frequency, index) in frequences"
                                                            :key="index"
                                                            :value="frequency.id"
                                                        >
                                                            {{ frequency.text }}
                                                        </option>
                                                    </b-select>
                                                </b-field>
                                            </div>
                                            <div class="col-md-6">
                                                <b-field :label="$t('global.recipient')">
                                                    <b-input
                                                        v-model="modalData.recipient"
                                                        :placeholder="$t('global.recipient')"
                                                        required
                                                    />
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-6">
                                                <b-field label="Site">
                                                    <b-select
                                                        v-model="modalData.site"
                                                        placeholder="Site"
                                                        expanded
                                                        @input="siteSelected"
                                                    >
                                                        <option
                                                            v-for="(site, index) in sites"
                                                            :key="index"
                                                            :value="site"
                                                        >
                                                            {{ site.name }}
                                                        </option>
                                                    </b-select>
                                                </b-field>
                                            </div>
                                            <div class="col-md-6">
                                                <b-field :label="$t('commande.maincategory')">
                                                    <b-input
                                                        v-model="modalData.main_category"
                                                        :placeholder="$t('commande.maincategory')"
                                                        disabled=""
                                                    />
                                                </b-field>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-12">
                                                <b-field :label="$t('alertes-partenaires.multipleselect')">
                                                    <div class="d-flex flex-column w-100">
                                                        <b-taglist class="mb-0">
                                                            <b-tag
                                                                v-for="(category, index) in selectedCategory"
                                                                :key="index"
                                                            >
                                                                {{ category.name }}
                                                            </b-tag>
                                                        </b-taglist>
                                                        <b-dropdown
                                                            v-model="selectedCategory"
                                                            max-height="100"
                                                            multiple
                                                            scrollable
                                                            expanded
                                                            mobile-modal
                                                        >
                                                            <template #trigger>
                                                                <b-button type="is-warning" icon-right="caret-down">
                                                                    Catégories ({{ selectedCategory.length }})
                                                                </b-button>
                                                            </template>
                                                            <b-dropdown-item
                                                                v-for="(group, index) in groups"
                                                                :key="index"
                                                                :value="group"
                                                            >
                                                                {{ group.name }}
                                                            </b-dropdown-item>
                                                        </b-dropdown>
                                                    </div>
                                                </b-field>
                                            </div>
                                        </div>
                                        <div v-if="modalData.site && modalData.site.group_id === 1" class="row mt-3">
                                            <div class="col-md-4">
                                                <b-button class="btn-secondary" @click="addParents">
                                                    {{ $t('alertes-partenaires.addparent') }}
                                                </b-button>
                                            </div>
                                            <div class="col-md-4">
                                                <b-button class="btn-primary" @click="addChilds">
                                                    {{ $t('alertes-partenaires.addchild') }}
                                                </b-button>
                                            </div>
                                        </div>
                                        <div id="option2" class="mt-3">
                                            <p><strong>Options</strong></p>
                                            <b-field>
                                                <b-checkbox
                                                    v-model="modalData.no_partner"
                                                    :true-value="true"
                                                    :false-value="false"
                                                    type="is-warning"
                                                >
                                                    Recevoir les mots clés sans partenaires intéressés
                                                </b-checkbox>
                                            </b-field>
                                            <b-field>
                                                <b-checkbox
                                                    v-model="modalData.two_month"
                                                    :true-value="true"
                                                    :false-value="false"
                                                    type="is-warning"
                                                >
                                                    Recevoir les alertes des 2 mois précédents
                                                </b-checkbox>
                                            </b-field>
                                        </div>
                                    </form>
                                </template>
                            </modal>
                            <div class="columnsChoice d-flex" style="gap: 0.5rem">
                                <PerPageSelector v-model="per_page" :loading="loading" @perPageChange="fetchAlerts" />
                                <TableColumnSelect v-model="columns" class="mr-2" />
                                <b-button
                                    :label="$t('global.instructions')"
                                    type="is-warning"
                                    tag="a"
                                    class="text-decoration-none"
                                    href="https://www.nextlevel.link/besoins-de-nos-clients.html"
                                    target="_blank"
                                    icon-left="info-circle"
                                />
                            </div>
                        </div>

                        <b-table
                            :data="list"
                            :mobile-cards="true"
                            :debounce-search="700"
                            paginated
                            backend-pagination
                            :total="total"
                            :per-page="per_page"
                            backend-filtering
                            backend-sorting
                            :striped="true"
                            :loading="loading"
                            pagination-rounded
                            @page-change="onPageChange"
                            @filters-change="onFilterChange"
                            @sort="onSortChange"
                        >
                            <b-table-column
                                field="libelle"
                                sortable
                                :label="$t('alertes-partenaires.text')"
                                :visible="columns[0].visible"
                                :searchable="isSearchable"
                            >
                                <template v-slot="{ row }">
                                    {{ row.libelle }}
                                </template>
                                <div slot="searchable" slot-scope="props">
                                    <div class="w-100 mx-auto">
                                        <input
                                            v-model="props.filters[props.column.field]"
                                            type="text"
                                            class="form-control form-control-sm"
                                            :placeholder="$t('alertes-partenaires.text')"
                                        />
                                    </div>
                                </div>
                            </b-table-column>

                            <b-table-column
                                field="site.group_id"
                                sortable
                                :label="$t('global.category')"
                                :visible="columns[1].visible"
                                :searchable="isSearchable"
                            >
                                <template v-slot="{ row }">
                                    {{ row.site.group.name }}
                                </template>
                                <div slot="searchable" slot-scope="props">
                                    <div class="w-100">
                                        <select
                                            v-model="props.filters[props.column.field]"
                                            class="form-control form-control-sm p-1"
                                        >
                                            <option :value="undefined">
                                                {{ $t('global.all') }}
                                            </option>
                                            <option v-for="i in usedGroups" :key="i.id" :value="i.id">
                                                {{ i.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </b-table-column>

                            <b-table-column
                                field="destinataire"
                                sortable
                                :label="$t('global.recipient')"
                                :visible="columns[2].visible"
                                :searchable="isSearchable"
                            >
                                <template v-slot="{ row }">
                                    {{ row.destinataire }}
                                </template>
                                <div slot="searchable" slot-scope="props">
                                    <div class="w-100 mx-auto">
                                        <input
                                            v-model="props.filters[props.column.field]"
                                            type="text"
                                            class="form-control form-control-sm"
                                            :placeholder="$t('global.recipient')"
                                        />
                                    </div>
                                </div>
                            </b-table-column>

                            <b-table-column
                                field="frequence"
                                sortable
                                :label="$t('alertes-partenaires.frequency')"
                                :visible="columns[3].visible"
                                :searchable="isSearchable"
                            >
                                <template v-slot="{ row }">
                                    {{ frequences.find(x => x.id === row.frequence).text }}
                                </template>
                                <div slot="searchable" slot-scope="props">
                                    <select
                                        v-model="props.filters[props.column.field]"
                                        class="form-control form-control-sm"
                                    >
                                        <option :value="undefined">Tous</option>
                                        <option v-for="freq in frequences" :key="'freq_' + freq.id" :value="freq.id">
                                            {{ freq.text }}
                                        </option>
                                    </select>
                                </div>
                            </b-table-column>

                            <b-table-column
                                field="no_partner"
                                sortable
                                :label="$t('alertes-partenaires.nointerested')"
                                :visible="columns[4].visible"
                            >
                                <template v-slot="{ row }">
                                    {{ row.no_partner ? 'Oui' : 'Non' }}
                                </template>
                            </b-table-column>

                            <b-table-column
                                field="category"
                                width="500"
                                sortable
                                :label="$t('alertes-partenaires.categoryalert')"
                                :visible="columns[5].visible"
                            >
                                <template v-slot="{ row }">
                                    <template>
                                        <div>
                                            <span
                                                v-for="cat in row.category_id.split(',')"
                                                :key="'_row_' + row.id + '_cat_' + cat"
                                                class="tag mr-1"
                                            >
                                                {{ displayCategoryName(cat) }}
                                            </span>
                                        </div>
                                    </template>
                                </template>
                            </b-table-column>

                            <b-table-column field="actions" label="Actions">
                                <template v-slot="{ row }">
                                    <b-button
                                        class="mr-2"
                                        type="is-light"
                                        icon-pack="fas"
                                        icon-left="cog"
                                        @click="edit(row)"
                                    />
                                    <b-button
                                        type="is-danger"
                                        icon-pack="fas"
                                        icon-left="trash"
                                        @click="deleteItem(row)"
                                    />
                                </template>
                            </b-table-column>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Modal from '../../components/UI/Modal/Modal'
    import PerPageSelector from '../../components/UI/Table/PerPageSelector'
    import TableColumnSelect from '../../components/UI/Table/TableColumnSelect'

    export default {
        title: 'alertes.title',
        components: { TableColumnSelect, PerPageSelector, Modal },
        data() {
            return {
                preloading: false,
                loading: false,
                per_page: 25,
                editable: null,
                isSearchable: true,
                frequences: [
                    { text: 'Quotidienne', id: 1 },
                    { text: 'Hebdomadaire', id: 7 },
                    { text: 'Mensuelle', id: 30 }
                ],
                usedGroups: [],
                columns: [
                    {
                        label: this.$t('alertes-partenaires.text')
                    },
                    {
                        label: this.$t('global.category')
                    },
                    {
                        label: this.$t('global.recipient')
                    },
                    {
                        label: this.$t('alertes-partenaires.frequency')
                    },
                    {
                        label: this.$t('alertes-partenaires.nointerested')
                    },
                    {
                        label: this.$t('alertes-partenaires.categoryalert')
                    }
                ],
                showAlertModal: false,
                alertModalType: 'add',
                modalData: {},
                modalLoading: false,
                sites: [],
                selectedCategory: []
            }
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('alert_partner', ['list', 'total']),
            ...mapGetters('group', { groups: 'list', groupLoaded: 'loaded' }),
            alertModalTitle() {
                return this.alertModalType === 'add' ? 'Ajouter une alerte' : 'Modifier une alerte'
            }
        },
        mounted() {
            if (!this.groupLoaded) {
                this.getGroupList()
            }
            this.fetchAlerts()
        },
        methods: {
            ...mapActions('alert_partner', {
                getAlerts: 'list',
                createAlert: 'create',
                updateAlert: 'update',
                deleteAlert: 'delete'
            }),
            ...mapActions('group', { getGroupList: 'list' }),
            ...mapActions('sites', { getSites: 'getAllSitesFromUser' }),
            fetchAlerts() {
                this.loading = true

                var sort = this.sort
                if (this.sortOrder === 'desc') {
                    sort = '-' + this.sort
                }
                let filter = null
                if (this.filters) {
                    filter = []
                    Object.keys(this.filters).forEach(key => {
                        filter[key] = this.filters[key]
                    })
                }

                this.getAlerts({
                    sort: sort,
                    page: this.page,
                    rowsPerPage: this.per_page,
                    filter: filter,
                    siteId: this.siteId
                })
                    .then(() => {
                        this.findUsedGroups()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            onPageChange(page) {
                this.page = page
                this.fetchAlerts()
            },
            onFilterChange(filters) {
                this.filters = filters
                this.fetchAlerts()
            },
            onSortChange(field, order) {
                this.sort = field
                this.sortOrder = order
                this.fetchAlerts()
            },
            edit(alert) {
                this.modalLoading = true
                this.showAlertModal = true
                this.alertModalType = 'edit'
                this.modalData = {}

                const getSites = this.getSites()
                const listGroup = this.getGroupList()

                Promise.all([getSites, listGroup]).then(values => {
                    let sites = values[0]

                    this.sites = sites.map(site => {
                        return {
                            id: site.id,
                            name: site.url,
                            group_id: site.group_id
                        }
                    })

                    this.modalData = {
                        id: alert.id,
                        label: alert.libelle,
                        frequences: alert.frequence,
                        recipient: alert.destinataire,
                        main_category: alert.main_category,
                        no_partner: alert.no_partner,
                        two_month: alert.two_month
                    }

                    if (this.sites.length > 0) {
                        this.modalData.site = this.sites.find(site => {
                            if (site.id === alert.site_id) {
                                return site
                            }
                        })
                    }

                    if (alert.category_id && !Array.isArray(alert.category_id)) {
                        alert.category_id = alert.category_id.split(',')
                    }
                    this.selectedCategory = []
                    this.modalData.main_category = alert.site.group.name

                    for (let idx = 0; idx < alert.category_id.length; idx++) {
                        const category = parseInt(alert.category_id[idx])
                        this.selectedCategory.push(
                            this.groups.find(group => {
                                return group.id === category
                            })
                        )
                    }

                    this.modalLoading = false
                })
            },
            deleteItem(alert) {
                confirm('Voulez-vous effacer cette alerte ?') &&
                    this.deleteAlert(alert.id)
                        .then(() => {
                            this.fetchAlerts()
                        })
                        .catch(response => {
                            if (response) {
                                alert(response.data.message)
                            }
                        })
                        .finally()
            },
            lang(l) {
                if (l === 'com_en') {
                    return 'en'
                }
                return 'fr'
            },
            displayCategoryName(cat) {
                let category = this.groups.find(x => x.id === parseInt(cat))
                return category ? category.name : ''
            },
            findUsedGroups() {
                for (const alert of this.list) {
                    let group = this.groups.find(group => group.id === alert.site.group_id)
                    if (this.usedGroups.find(usedGroup => usedGroup.id === group.id) === undefined) {
                        this.usedGroups.push(group)
                    }
                }
            },
            openAlertModal() {
                this.modalLoading = true
                this.showAlertModal = true
                this.modalData = {}

                const getSites = this.getSites()
                const listGroup = this.getGroupList()

                Promise.all([getSites, listGroup]).then(values => {
                    let sites = values[0]

                    this.modalData.lang = this.user.locale === 'en' ? 'com_en' : 'fr_fr'

                    this.sites = sites.map(site => {
                        return {
                            id: site.id,
                            name: site.url,
                            group_id: site.group_id
                        }
                    })

                    this.modalData.recipient = this.user.email

                    this.modalLoading = false
                })
            },
            addParents() {
                let parentsList = this.groups
                    .filter(group => !group.parent_id)
                    .map(group => {
                        return group
                    })
                for (let idx = 0; idx < parentsList.length; idx++) {
                    const parent = parentsList[idx]
                    if (!this.selectedCategory.find(group => group.id === parent.id)) {
                        this.selectedCategory.push(parent)
                    }
                }
            },
            addChilds() {
                let childsList = this.groups
                    .filter(group => group.parent_id)
                    .map(group => {
                        return group
                    })

                for (let idx = 0; idx < childsList.length; idx++) {
                    const child = childsList[idx]
                    if (!this.selectedCategory.find(group => group.id === child.id)) {
                        this.selectedCategory.push(child)
                    }
                }
            },
            siteSelected() {
                this.modalData.category_id = []
                this.selectedCategory = []
                this.modalData.main_category = ''
                if (this.modalData.site) {
                    for (let idx = 0; idx < this.groups.length; idx++) {
                        const group = this.groups[idx]
                        if (group.id === this.modalData.site.group_id) {
                            this.modalData.main_category = group.name
                            this.selectedCategory.push(group)
                        }
                        if (this.modalData.site.group_id !== 1 && group.parent_id === this.modalData.site.group_id) {
                            this.selectedCategory.push(group)
                        }
                    }
                }
            },
            addOrEditAlert() {
                if (
                    this.modalData.recipient != null &&
                    this.modalData.site.id != null &&
                    this.modalData.frequences != null
                ) {
                    if (this.modalData.id) {
                        this.editAlert()
                    } else {
                        this.addAlert()
                    }
                }
            },
            addAlert() {
                this.createAlert({
                    libelle: this.modalData.label,
                    frequence: this.modalData.frequences,
                    destinataire: this.modalData.recipient,
                    site_id: this.modalData.site.id,
                    main_category: this.modalData.main_category,
                    category_id: this.selectedCategory.map(cat => cat.id),
                    no_partner: this.modalData.no_partner,
                    two_month: this.modalData.two_month
                })
                    .then(() => {
                        this.showAlertModal = false
                        this.modalData = {}
                        this.fetchAlerts()
                    })
                    .catch(response => {
                        if (response.status === 422) {
                            this.$refs.alert.showValidationError(response.data)
                        } else {
                            this.$refs.alert.displayMsg('danger', response)
                        }
                    })
            },
            editAlert() {
                this.updateAlert({
                    id: this.modalData.id,
                    libelle: this.modalData.label,
                    frequence: this.modalData.frequences,
                    destinataire: this.modalData.recipient,
                    site_id: this.modalData.site.id,
                    main_category: this.modalData.main_category,
                    category_id: this.selectedCategory.map(cat => cat.id),
                    no_partner: this.modalData.no_partner,
                    two_month: this.modalData.two_month
                })
                    .then(() => {
                        this.showAlertModal = false
                        this.modalData = {}
                        this.fetchAlerts()
                    })
                    .catch(response => {
                        if (response.status === 422) {
                            this.$refs.alert.showValidationError(response.data)
                        } else {
                            this.$refs.alert.displayMsg('danger', response)
                        }
                    })
            }
        }
    }
</script>

<style></style>
